import React, { RefObject, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import heic2any from 'heic2any'
import PropagateLoader from 'react-spinners/PropagateLoader'

import ActionButtons from './EditInvoice/ActionButtons'
import IconDiv from './EditInvoice/IconDiv'
import { InvoiceType } from '../../../queries/invoices'
import { FilePreview, FileResponse } from '../../../constants/types'
import { getFilePath, isHeic, isPdf } from '../../../utils/functions'
import useLogin from '../../../hooks/useLogin'

interface Props {
  getFileToSent: (file: File[]) => void
  setEditFirstFile: (state: boolean) => void
  editInvoiceFilePath?: string
  fileType: string
  handleEditInvoiceRotate?: () => void
  imageChanged?: number
  invoice: InvoiceType
  disabled?: boolean
  previewVisible: boolean
  setPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>
  setLoadingFile: React.Dispatch<React.SetStateAction<boolean>>
  loadingFile: boolean
  acceptedFiles: File[]
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  setFile: React.Dispatch<React.SetStateAction<FilePreview[]>>
  file: FilePreview[]
  filePreview?: FileResponse
  previewFiles: FileResponse[] | undefined
  editingInvoice?: boolean
  duplicatingInvoice?: boolean
  setDeletedFiles: React.Dispatch<React.SetStateAction<FileResponse[]>>
  displayDelete: boolean
  hideRotate?: boolean
  onlyActionButtons?: boolean
  hideDelete?: boolean
}

export default ({
  getFileToSent,
  editInvoiceFilePath,
  handleEditInvoiceRotate,
  fileType,
  imageChanged,
  invoice,
  loadingFile,
  disabled,
  previewVisible,
  setPreviewVisible,
  setLoadingFile,
  acceptedFiles,
  getRootProps,
  getInputProps,
  file,
  previewFiles,
  setFile,
  filePreview,
  editingInvoice,
  duplicatingInvoice,
  setDeletedFiles,
  displayDelete,
  onlyActionButtons,
  hideRotate,
  hideDelete,
}: Props) => {
  const [buttonsOverlapping, setButtonsOverlapping] = useState(false)
  const { token } = useLogin()

  const buttonsRef = useRef<HTMLDivElement>(null)
  const fileRef = useRef<HTMLImageElement | HTMLIFrameElement>(null)

  useEffect(() => {
    getFileToSent([])

    acceptedFiles.map(async (acceptedFile) => {
      setLoadingFile(true)
      if (isHeic(acceptedFile)) {
        const blob = acceptedFiles[0]

        const converted = await heic2any({
          blob,
          toType: 'image/png',
          quality: 0.5,
        })

        setFile((file) => [
          ...file,
          {
            file: acceptedFile,
            preview: converted ? URL.createObjectURL(converted as Blob) : '',
          } as FilePreview,
        ])
        setLoadingFile(false)
      } else {
        setLoadingFile(false)
      }
    })
  }, [acceptedFiles])

  useEffect(() => {
    getFileToSent(acceptedFiles)
  }, [acceptedFiles])

  useEffect(() => {
    handleOverlapp()
  }, [imageChanged])

  const getUrl = () => {
    if (editInvoiceFilePath && !file) {
      return editInvoiceFilePath
    } else if (filePreview) {
      return getFilePath(invoice, filePreview!, token!)
    } else if (file[0]) {
      return file[0].preview
    }
    return ''
  }

  const url = getUrl()

  function handleView() {
    const rootProps = !disabled ? getRootProps({ className: 'dropzone' }) : {}

    const image = (
      <ImageContainer {...rootProps} disabled={disabled || filePreview !== undefined}>
        {url && (
          <Image
            ref={fileRef as RefObject<HTMLImageElement>}
            src={`${url}${!url.startsWith('blob') ? `&?count=${imageChanged}` : ''}`}
          />
        )}
      </ImageContainer>
    )

    const pdf = (
      <FrameContainer {...rootProps} disabled={disabled || filePreview !== undefined}>
        {url && <PdfView ref={fileRef as RefObject<HTMLIFrameElement>} src={url} />}
      </FrameContainer>
    )

    if ((file && file[0] && isPdf(file[0].file.name)) || (filePreview && isPdf(filePreview.url))) {
      return pdf
    }

    return image
  }

  function handleOverlapp() {
    if (!buttonsRef.current || !fileRef.current) {
      return false
    }

    const buttons = buttonsRef.current.getBoundingClientRect()
    const file = fileRef.current.getBoundingClientRect()

    const overlap = !(
      buttons.right < file.left ||
      buttons.left > file.right ||
      buttons.bottom < file.top ||
      buttons.top > file.bottom
    )

    if (buttonsOverlapping !== overlap) {
      setButtonsOverlapping(overlap)
    }
  }

  const handleDelete = () =>
    setDeletedFiles((deletedFiles) => [
      ...deletedFiles,
      { url: filePreview!.url, order: filePreview!.order },
    ])

  if (loadingFile)
    return (
      <LoaderOverlayDropzone>
        <LoaderContainer>
          <PropagateLoader
            size={12.5}
            color="#F29312"
            css={'display:flex;justify-content:center;align-items:center;'}
          />
        </LoaderContainer>
      </LoaderOverlayDropzone>
    )

  const displayAddFileDropzone =
    !previewFiles?.length || (!editInvoiceFilePath && !filePreview && !file.length)

  const hasFilesToPreview = previewFiles?.length ? previewFiles?.length > 0 : file?.length > 0

  return (
    <>
      {onlyActionButtons && (
        <ActionButtons
          coloredButtons={buttonsOverlapping}
          invoice={invoice}
          fileUrl={file && file[0] && file[0].preview}
          filePreviewUrl={url}
          handleRotate={handleEditInvoiceRotate ? handleEditInvoiceRotate : () => null}
          handleDelete={handleDelete}
          fileTypes={{
            edit: fileType || '',
            // @ts-ignore
            file: file && file[0] && file[0].type,
          }}
          previewVisible={previewVisible}
          setPreviewVisible={setPreviewVisible}
          disabled={disabled}
          displayDelete={displayDelete}
          urlToDownload={url}
          activePreview={filePreview}
          hideRotate={hideRotate}
          hideDelete={hideDelete}
          imageChanged={imageChanged}
        />
      )}
      {!disabled && !hasFilesToPreview && displayAddFileDropzone && (
        <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
          <DropzoneInput {...getInputProps()} />
          <AddImage src="/img/add-invoice.svg" />
          <StyledText>UPLOAD PICTURE</StyledText>
        </StyledDropzone>
      )}
      {disabled && <>{handleView()}</>}
      {(!editingInvoice || duplicatingInvoice) && file.length > 0 && (
        <EditContainer>
          <Container>
            <EditInput {...getInputProps()} />
          </Container>
          {handleView()}
        </EditContainer>
      )}
      {hasFilesToPreview && editInvoiceFilePath && !onlyActionButtons && (
        <>
          <ActionButtons
            coloredButtons={buttonsOverlapping}
            invoice={invoice}
            fileUrl={file && file[0] && file[0].preview}
            filePreviewUrl={url}
            handleRotate={handleEditInvoiceRotate ? handleEditInvoiceRotate : () => null}
            handleDelete={handleDelete}
            fileTypes={{
              edit: fileType || '',
              // @ts-ignore
              file: file && file[0] && file[0].type,
            }}
            previewVisible={previewVisible}
            setPreviewVisible={setPreviewVisible}
            disabled={disabled}
            displayDelete={displayDelete}
            urlToDownload={url}
            activePreview={filePreview}
            hideRotate={hideRotate}
            hideDelete={hideDelete}
            imageChanged={imageChanged}
          />
          <EditContainer>
            <Container ref={buttonsRef}>
              <EditInput {...getInputProps()} disabled={disabled} />
            </Container>
            {handleView()}
          </EditContainer>
        </>
      )}
    </>
  )
}

const sizes = css`
  width: 23em;
  height: 31.4em;
  margin-bottom: 1rem;

  @media (max-width: 960px) {
    width: 21.5em;
  }

  @media (max-width: 560px) {
    width: 18em;
    height: 22em;
  }
`

const StyledDropzone = styled.div`
  ${sizes}
  border-radius: 20px;
  background-image: url('/img/dropzone-border.svg');
  background-color: #4a4c57;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
`

interface ImageContainerProps {
  imageUrl?: string
  disabled?: boolean
}

const ImageContainer = styled.div<ImageContainerProps>`
  ${sizes}
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
`

const PdfView = styled.iframe`
  ${sizes}
  pointer-events: none;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 0;
  margin: 0;
`

const DropzoneInput = styled.input`
  outline: none;
  border: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const AddImage = styled.img`
  cursor: pointer;
`

const StyledText = styled.span`
  margin-top: 1em;
  font-family: Montserrat;
  font-size: 0.8em;
  color: #9198a0;
  font-weight: 600;
  cursor: pointer;
  line-height: 16px;
  width: 4.5em;
`

const EditInput = styled(DropzoneInput)`
  display: block !important;
  opacity: 0;
  height: 100%;
  width: 100%;
`

const LoaderOverlayDropzone = styled.div`
  width: 23em;
  height: 32em;
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  position: absolute;
  z-index: 200;
  height: 100%;
`

const EditContainer = styled.div`
  position: relative;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 20px;
`
interface FrameContainerProps {
  disabled?: boolean
}

const FrameContainer = styled.div<FrameContainerProps>`
  cursor: pointer;
  outline: none;
  pointer-events: none;
  margin-bottom: 1rem;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`
