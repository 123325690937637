import { gql } from '@apollo/client'

export default gql`
  query invoice($id: String!) {
    invoice(id: $id) {
      id
      userId
      user {
        id
        displayName
      }
      isConcept
      date
      totalPrice
      basePrice
      vatState
      updatedAt
      description
      documentNumber
      files {
        url
        order
      }
      category {
        id
        displayName
        vat
      }
      status {
        displayName
        id
      }
      issuers {
        user {
          username
          displayName
          id
        }
        category {
          id
          displayName
          vat
        }
        vatState
        basePrice
        totalPrice
        categoryId
        realPrice
      }
      currency {
        symbol
        id
      }
    }
  }
`
