import { FormDataType } from '../constants/formsData'
import { HandleChangeEvent } from '../components/layout/Form/Input'
import { InputValueItemType, InputValueStateType } from '../constants/types'

const searchValue = ','
const replaceValue = '.'
const enLang = navigator.language.startsWith('en')

const formatPrice = (price: string) =>
  Math.abs(parseFloat(price && enLang ? price.replace(searchValue, replaceValue) : price))

const comparePrice = (
  item: FormDataType,
  event: HandleChangeEvent,
  inputValue: InputValueStateType
) => {
  if (!inputValue?.price || !inputValue?.vatPrice) {
    return false
  }
  const compared = item.name === 'vatPrice' ? inputValue.price : inputValue.vatPrice
  const value = formatPrice(event.target.value)

  switch (item.name) {
    case 'vatPrice':
      inputValue.price.isInvalid = false
      return value > formatPrice(compared.value ? compared.value : '0')
    case 'price':
      if (!compared.value) return false

      inputValue.vatPrice.isInvalid = false
      return value < formatPrice(compared.value)
    default:
      return false
  }
}

export default comparePrice
