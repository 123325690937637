export default function handleMathSymbols(value: string): string | number {
  if (!value) {
    return value
  }

  const operandEvaluation = (numbers: number[], operand: string) => {
    if (operand === '+') {
      return numbers[0] + numbers[1]
    } else if (operand === '-') {
      return numbers[0] - numbers[1]
    } else if (operand === '*') {
      return numbers[0] * numbers[1]
    } else if (operand === '/') {
      return numbers[0] / numbers[1]
    } else {
      return value
    }
  }

  const mathSymbols = ['+', '-', '*', '/']

  const expressionParts = value.split(/[\+\-\*\/]/g)
  if (expressionParts.length > 2) {
    const numbers = expressionParts?.map((part) => parseFloat(part))

    if (!numbers || numbers.length < 2) {
      return value
    }

    const operand = Array.from(value)?.find((item) => mathSymbols.includes(item))

    if (!operand) {
      return value
    }

    const result = operandEvaluation(numbers, operand) + value.slice(-1)

    return result
  }

  if (value.endsWith('=')) {
    const expression = value.split('=').at(0)
    if (!expression) {
      return value
    }

    const expressionParts = expression?.split(/[\+\-\*\/]/g)
    const operand = Array.from(expression)?.find((item) => mathSymbols.includes(item))

    if (!operand) {
      return value
    }

    const numbers = expressionParts?.map((part) => parseFloat(part))

    if (!numbers || numbers.length !== 2) {
      return value
    }

    return operandEvaluation(numbers, operand)
  }

  return value
}
