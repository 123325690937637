import { gql } from '@apollo/client'

export interface InvoiceStatusCategoryUserType {
  displayName: string
  id: number
  username?: string
  paid?: boolean
}

export interface InvoiceUserType {
  user: InvoiceStatusCategoryUserType
  category: InvoiceStatusCategoryUserType
  categoryId: number
  totalPrice: number
  basePrice: number
  vatState: string
  realPrice: number
}

interface CurrencyType {
  symbol: string
}

export interface InvoiceFilesType {
  url: string
  order: number
}

export interface InvoiceType {
  id: string
  userId: string
  date: string
  totalPrice: number
  basePrice: number
  realPrice: number
  approved: boolean
  documentNumber: number
  vatState: string
  category: InvoiceStatusCategoryUserType
  status: InvoiceStatusCategoryUserType
  issuers: InvoiceUserType[]
  user: {
    id: string
    displayName: string
  }
  isConcept: boolean
  currency: CurrencyType
  files: InvoiceFilesType[]
  description: string
  isSalary?: boolean
}

export default gql`
  query invoices(
    $yearMonth: String!
    $sortBy: String!
    $order: OrderInvoices!
    $filterBy: [String]!
    $filterValues: [Float]!
  ) {
    invoices(
      yearMonth: $yearMonth
      sortBy: $sortBy
      order: $order
      filterBy: $filterBy
      filterValues: $filterValues
    ) {
      id
      userId
      date
      totalPrice
      basePrice
      realPrice
      description
      vatState
      user {
        displayName
      }
      isConcept
      category {
        displayName
        id
      }
      status {
        displayName
        id
        paid
      }
      issuers {
        user {
          displayName
          id
        }
        category {
          id
          displayName
        }
        categoryId
        totalPrice
        basePrice
        realPrice
      }
      currency {
        symbol
        id
      }
      files {
        url
        order
      }
      approved
      documentNumber
    }
  }
`
