import { gql } from '@apollo/client'

export default gql`
  mutation editInvoiceFiles(
    $id: String!
    $files: [Upload]!
    $deletedFiles: [InvoiceFileInput!]
    $isConcept: Boolean!
  ) {
    editInvoiceFiles(id: $id, files: $files, deletedFiles: $deletedFiles, isConcept: $isConcept)
  }
`
