import styled, { css } from 'styled-components'

interface InvoiceProps {
  colored: boolean
  isConcept?: boolean
}

export const StyledInvoice = styled.tr<InvoiceProps>`
  background-color: #3a3c45;
  font-size: 0.82em;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #cbcfd3;
  height: 49px;

  @media (max-width: 960px) {
    font-size: 0.75em;
    padding-left: 0.5em;
  }

  @media (max-width: 645px) {
    width: 55em;
    padding-left: 0;
  }

  @media (max-width: 375px) {
    font-size: 0.69em;
    width: 61em;
  }

  ${({ colored }) =>
    colored &&
    css`
      background-color: #535562;
    `}

  &:hover {
    background-color: #535562;
  }
`

export const StyledGroupInvoice = styled(StyledInvoice)`
  background-color: #474a55;
`

export const GroupInvoiceWrapper = styled.div`
  width: 0.2rem;
`

interface GroupInterface {
  isSelected: boolean
}

export const GroupInvoiceContainer = styled.div<GroupInterface>`
  width: fit-content;
  position: relative;
  margin-left: -2.3rem;
  cursor: pointer;
  margin-top: 0.3rem;
  ${({ isSelected }) => isSelected && 'margin-top: 0.8rem;'};

  @media (max-width: 645px) {
    margin-left: -3.3rem;
  }
`

export const Arrow = styled.div<GroupInterface>`
  background-image: url('/img/arrow-right.svg');
  width: 2rem;
  height: 2rem;
  overflow-x: unset;
  background-position-y: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  transform-origin: center center;
  transform: rotate(${({ isSelected }) => (isSelected ? '90deg' : '-90deg')});
`
interface CheckboxContainerProps {
  isSalary: boolean
}

export const CheckboxContainer = styled.span<CheckboxContainerProps>`
  display: ${({ isSalary }) => (isSalary ? 'none' : 'flex')};
  align-items: center;
  margin-left: 0.6em;
`

export const StickyContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Margin = styled.span`
  text-align: left;

  @media (max-width: 830px) {
    margin-right: 0.5em;
  }
`

export const Details = styled(Margin)`
  padding-bottom: 0.05em;
  border-bottom: 1px solid;
  cursor: pointer;

  @media (max-width: 960px) {
    border-bottom: none;
    text-decoration: underline;
  }

  @media (max-width: 645px) {
    display: flex;
    justify-content: flex-end;
  }
`

export const StyledButton = styled.button`
  display: inherit;
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export const StyledStatus = styled(Margin)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 960px) {
    width: 4em;
    white-space: normal;
  }

  @media (max-width: 645px) {
    width: 7em;
    margin-right: 2em;
  }
`

export const StyledUser = styled(Margin)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 960px) {
    width: 4em;
    white-space: normal;
  }

  @media (max-width: 645px) {
    width: 5em;
    margin-right: 1em;
  }
`

export const StyledDate = styled(Margin)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 830px) {
    white-space: normal;
  }

  @media (max-width: 645px) {
    margin-right: 2em;
  }
`

export const Actions = styled.span`
  display: flex;
  justify-content: start;
  margin-left: 30px;
  width: 1rem;
  height: 1rem;

  @media (max-width: 830px) {
    margin-left: 20px;
  }

  @media (max-width: 375px) {
    margin-left: 14px;
  }
`

export const BrowserActions = styled(Actions)`
  @media (min-width: 960px) {
    display: none;
  }
`

export const ActionsImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
`

export const NoScrollable = styled.td`
  @media (max-width: 645px) {
    flex-direction: row;
    background-color: inherit;
    position: relative;
    z-index: 100;
    height: 49px;
    position: sticky;
    left: 0px;
  }
`

export const StyledCategory = styled(Margin)`
  display: flex;
  justify-content: flex-start;
  display: inline;

  @media (max-width: 645px) {
    width: 6em;
    margin-right: 3em;
  }
`

export const StyledPrice = styled(Margin)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 645px) {
    width: 7em;
    margin-right: 1em;
  }

  @media (max-width: 375px) {
    white-space: normal;
  }
`

export const StyledDocumentNumber = styled(Margin)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 645px) {
    width: 8em;
    margin-right: 1.5em;
  }

  @media (max-width: 375px) {
    white-space: normal;
  }
`

export const MobileOnly = styled.td`
  display: none;
  @media (max-width: 960px) {
    display: table-cell;
  }
`

export const DesktopOnly = styled.td`
  display: table-cell;
  @media (max-width: 960px) {
    display: none;
  }
`

interface SeparatorInterface {
  visible: boolean
}

export const Separator = styled.tr<SeparatorInterface>`
  ${({ visible }) => !visible && 'visibility: hidden;'}
`

export const BottomSeparator = styled.tr<SeparatorInterface>`
  outline: 1px solid #535562;
  ${({ visible }) => !visible && 'visibility: hidden;'}
`
