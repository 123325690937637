interface InputValue {
  value: string
  callback: (name: string, value: string) => void
}

export const calculatePriceWithoutVat = (props: InputValue) => {
  const { value, callback } = props
  if (!value) {
    return
  }
  const withoutVat = (Number(value) / 1.21).toFixed(2)
  callback('vatPrice', withoutVat.toString())
}

export const calculatePriceWithVat = (props: InputValue) => {
  const { value, callback } = props
  if (!value) {
    return
  }
  const withVat = (Number(value) * 1.21).toFixed(2)

  callback('price', withVat.toString())
}
