import React, { Dispatch, SetStateAction, useState } from 'react'
import styled, { css } from 'styled-components'
import { InvoiceType, InvoiceUserType } from '../../../queries/invoices'
import Dropdown from '../../layout/ActionsDropdown/Dropdown'
import InvoiceDetail from './InvoiceDetail'
import StyledCheckbox from '../../layout/StyledCheckbox'
import WithUnderline from '../../layout/WithUnderline'
import formatPrice from '../../layout/formatPrice'
import { deviceType, isIPad13, isTablet } from 'react-device-detect'
import { ContextMenuEvent } from './InvoiceSection'
import { selectOptionsInterface, variablesType } from '../../../constants/types'
import InvoiceGroupSection from './InvoiceGroupSection'
import {
  Separator,
  BottomSeparator,
  StyledInvoice,
  StyledCategory,
  StyledDate,
  StyledDocumentNumber,
  StyledPrice,
  StyledStatus,
  StyledUser,
  StickyContainer,
  Actions,
  BrowserActions,
  ActionsImage,
  CheckboxContainer,
  MobileOnly,
  DesktopOnly,
  NoScrollable,
  StyledButton,
  GroupInvoiceContainer,
  GroupInvoiceWrapper,
  Arrow,
  Details,
} from './Components'
interface Props {
  invoice: InvoiceType
  isGroupInvoice: boolean
  renderInvoiceLine: () => JSX.Element[]
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, invoice: InvoiceType) => void
  handleActionClick: (invoiceId: string, menuOpening: boolean) => void
  handleDelete: (ids: string[]) => void
  handleApprove: (ids: string[]) => void
  handleUnapprove: (ids: string[]) => void
  invoiceCount: number
  totalCost: boolean
  selectedInvoiceCategories: string[]
  selectedInvoicesIds: string[]
  selectedInvoicesStatuses: string[]
  variables: variablesType
  handleFilter: (filterBy: string, filterId: number | undefined) => void
  isChecked: boolean
  closedMonth: boolean
  invoicesForDetail: InvoiceType[]
  onContextMenu: (
    event: ContextMenuEvent,
    invoice: InvoiceType,
    isIssuerRow?: boolean,
    isGroupInvoice?: boolean,
    isConcept?: boolean
  ) => void
  selectOptions: selectOptionsInterface
  titleText?: string
  setIssuerRow: Dispatch<SetStateAction<boolean>>
}

export default (props: Props) => {
  const [color, setColor] = useState(false)
  const [isGroupInvoiceOpen, setIsGroupInvoiceOpen] = useState(false)
  const [rightClickColor, setRightClickColor] = useState(false)
  const [invoiceDetailVisible, setInvoiceDetailVisible] = useState(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isIssuerHovered, setIsIssuerHovered] = useState<boolean>(false)

  const { invoice } = props

  function getPrice() {
    return props.totalCost ? formatPrice(invoice.totalPrice) : formatPrice(invoice.realPrice)
  }

  function getIssuerPrice(issuer: InvoiceUserType) {
    return props.totalCost ? formatPrice(issuer.totalPrice) : formatPrice(issuer.realPrice)
  }

  function handleInvoiceDetailClose() {
    setInvoiceDetailVisible(false)
    setColor(false)
    setRightClickColor(false)
  }

  function showInvoiceDetail() {
    setInvoiceDetailVisible(true)
  }

  function deviceActions() {
    const tablet = isTablet || isIPad13 || deviceType === 'mobile' || deviceType === 'tablet'
    const Component = tablet ? Actions : BrowserActions

    return (
      <Component>
        <ActionsImage src="/img/actions-dots.svg" />
      </Component>
    )
  }

  function handleRightClick(event: ContextMenuEvent, issuerRowRightClick?: boolean) {
    if (!invoice.isSalary) {
      props.setIssuerRow(issuerRowRightClick ?? false)
      props.onContextMenu(
        event,
        invoice,
        props.isGroupInvoice || issuerRowRightClick,
        props.isGroupInvoice,
        invoice.isConcept
      )
    }
  }

  function renderDocumentNumber() {
    return (
      <StyledDocumentNumber>
        {invoice.documentNumber && props.closedMonth ? invoice.documentNumber : 'N/A'}
      </StyledDocumentNumber>
    )
  }

  return (
    <>
      <Separator key={`${invoice.id}_Line`} visible={!isHovered}>
        {props.renderInvoiceLine()}
      </Separator>
      <>
        <StyledInvoice
          data-testid={`${props.titleText?.toLowerCase()}-invoice-row`}
          colored={color}
          isConcept={invoice.isConcept}
          onContextMenu={(event) => handleRightClick(event, false)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <>
            <NoScrollable>
              <StickyContainer>
                {!invoice.isConcept && (
                  <CheckboxContainer isSalary={invoice.isSalary ?? false}>
                    <StyledCheckbox
                      type="checkbox"
                      name="image"
                      checked={props.isChecked}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.handleCheckboxChange(event, invoice)
                      }
                    />
                    <label htmlFor="image"></label>
                  </CheckboxContainer>
                )}
                <Dropdown
                  number={props.invoiceCount}
                  invoiceId={invoice.id}
                  unapproveInvoice={() => props.handleUnapprove(props.selectedInvoicesIds)}
                  deleteInvoices={() => props.handleDelete(props.selectedInvoicesIds)}
                  approveInvoices={() => props.handleApprove(props.selectedInvoicesIds)}
                  approvedInvoice={invoice.approved}
                  selectedInvoiceCategories={props.selectedInvoiceCategories}
                  selectedInvoiceStatuses={props.selectedInvoicesStatuses}
                  selectedInvoicesIds={props.selectedInvoicesIds}
                  variables={props.variables}
                  handleActionClick={props.handleActionClick}
                  showInvoiceDetail={showInvoiceDetail}
                  closedMonth={props.closedMonth}
                  isConcept={invoice?.isConcept}
                >
                  {deviceActions()}
                </Dropdown>
              </StickyContainer>
            </NoScrollable>
            <DesktopOnly>{renderDocumentNumber()}</DesktopOnly>
            <td>
              <StyledDate>{invoice.date}</StyledDate>
            </td>
            <td>
              <StyledStatus>
                <WithUnderline>
                  <StyledButton onClick={() => props.handleFilter('statusId', invoice.status.id)}>
                    {invoice.status.displayName}
                  </StyledButton>
                </WithUnderline>
              </StyledStatus>
            </td>
            <td>
              <StyledCategory>
                <WithUnderline>
                  <StyledButton
                    onClick={() => props.handleFilter('categoryId', invoice.category.id)}
                  >
                    {invoice.category.displayName}
                  </StyledButton>
                </WithUnderline>
              </StyledCategory>
            </td>
            <td>
              <StyledUser>
                <WithUnderline>
                  <StyledButton
                    onClick={() => props.handleFilter('userId', parseInt(invoice.userId))}
                  >
                    {invoice.user?.displayName ?? ''}
                  </StyledButton>
                </WithUnderline>
              </StyledUser>
            </td>
            <td>
              <StyledPrice>
                {getPrice()}&nbsp;{props.totalCost ? invoice.currency.symbol : 'CZK'}
              </StyledPrice>
            </td>
            <DesktopOnly>
              <Details>
                <StyledButton onClick={() => setInvoiceDetailVisible(true)}>
                  {invoice.isSalary ? '' : 'Preview'}
                </StyledButton>
              </Details>
            </DesktopOnly>
            <MobileOnly>{renderDocumentNumber()}</MobileOnly>
            {invoiceDetailVisible && (
              <InvoiceDetail
                handleClose={handleInvoiceDetailClose}
                invoicesForDetail={props.invoicesForDetail}
                invoiceId={invoice.id}
              />
            )}
          </>
          {props.isGroupInvoice && (
            <GroupInvoiceWrapper>
              <GroupInvoiceContainer
                onClick={() => setIsGroupInvoiceOpen(!isGroupInvoiceOpen)}
                isSelected={isGroupInvoiceOpen}
                aria-label="Expand group invoice"
              >
                <Arrow isSelected={isGroupInvoiceOpen} />
              </GroupInvoiceContainer>
            </GroupInvoiceWrapper>
          )}
        </StyledInvoice>
      </>
      {props.isGroupInvoice &&
        isGroupInvoiceOpen &&
        props.invoice.issuers.map((issuer: InvoiceUserType) => (
          <InvoiceGroupSection
            issuer={issuer}
            invoice={invoice}
            isGroupInvoiceOpen={isGroupInvoiceOpen}
            isGroupInvoice={props.isGroupInvoice}
            handleRightClick={handleRightClick}
            handleCheckboxChange={props.handleCheckboxChange}
            handleFilter={props.handleFilter}
            selectOptions={props.selectOptions}
            selectedInvoiceCategories={props.selectedInvoiceCategories}
            selectedInvoicesIds={props.selectedInvoicesIds}
            variables={props.variables}
            handleActionClick={props.handleActionClick}
            showInvoiceDetail={showInvoiceDetail}
            closedMonth={props.closedMonth}
            handleUnapprove={props.handleUnapprove}
            handleDelete={props.handleDelete}
            handleApprove={props.handleApprove}
            isChecked={props.isChecked}
            titleText={props.titleText}
            renderInvoiceLine={props.renderInvoiceLine}
            invoiceCount={props.invoiceCount}
            invoicesForDetail={props.invoicesForDetail}
            selectedInvoicesStatuses={props.selectedInvoicesStatuses}
            totalCost={props.totalCost}
            renderDocumentNumber={renderDocumentNumber}
            onContextMenu={props.onContextMenu}
            setIssuerRow={props.setIssuerRow}
            color={color}
            deviceActions={deviceActions}
            getIssuerPrice={getIssuerPrice}
            setInvoiceDetailVisible={setInvoiceDetailVisible}
            setIsIssuerHovered={setIsIssuerHovered}
          />
        ))}
      <BottomSeparator visible={isHovered || isIssuerHovered} />
    </>
  )
}
