import React from 'react'
import styled from 'styled-components'
import StyledText from './StyledText'
import Links from './Links'
import { Container, DownSection } from '../DropdownStyledComponents'
import Cancel from './Cancel'

interface Props {
  number: number
  handleClose: () => void
  handleBack: () => void
  unapproveInvoices: () => void
  approveInvoices: () => void
  approvedInvoice: boolean
  deleteInvoices: () => void
  selectedInvoicesIds: string[]
  selectedInvoiceCategories: string[]
  selectedInvoicesStatuses: string[]
  variables: any
  showInvoiceDetail: () => void
  closedMonth: boolean
  isConcept?: boolean
}

const ActionsOverlay = (props: Props) => {
  return (
    <Overlay>
      <StyledContainer>
        <TopSection>
          <TopText>SELECTED INVOICES</TopText>
          <TopText>({props.number})</TopText>
        </TopSection>
        <StyledDownSection>
          <Links
            handleClose={props.handleClose}
            handleBack={props.handleBack}
            unapproveInvoices={props.unapproveInvoices}
            deleteInvoices={props.deleteInvoices}
            approvedInvoice={props.approvedInvoice}
            selectedInvoices={props.number}
            selectedInvoicesIds={props.selectedInvoicesIds}
            selectedInvoiceCategories={props.selectedInvoiceCategories}
            selectedInvoicesStatuses={props.selectedInvoicesStatuses}
            variables={props.variables}
            approveInvoices={props.approveInvoices}
            showInvoiceDetail={props.showInvoiceDetail}
            closedMonth={props.closedMonth}
            isConcept={props?.isConcept}
          />
        </StyledDownSection>
        <Cancel handleCancel={props.handleClose} />
      </StyledContainer>
    </Overlay>
  )
}

export default ActionsOverlay

const Overlay = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.31);
`

const StyledDownSection = styled(DownSection)`
  flex: 1;
`

const StyledContainer = styled(Container)`
  height: 37em;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
`

const TopSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TopText = styled.span`
  ${StyledText}
  font-weight: 600;
`
