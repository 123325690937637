import React, { ReactElement, useState } from 'react'
import Dropdown from 'rc-dropdown'
import ActionsOverlay from './ActionsOverlay'
import 'rc-dropdown/assets/index.css'
import '../Dropdown.css'
import useLogin from '../../../hooks/useLogin'
import { variablesType } from '../../../constants/types'

interface Props {
  children: ReactElement
  number: number
  invoiceId: string
  unapproveInvoice: () => void
  deleteInvoices: () => void
  approvedInvoice: boolean
  approveInvoices: () => void
  handleActionClick: (invoiceId: string, menuOpening: boolean) => void
  selectedInvoicesIds: string[]
  selectedInvoiceCategories: string[]
  selectedInvoiceStatuses: string[]
  variables: variablesType
  showInvoiceDetail: () => void
  closedMonth: boolean
  isConcept?: boolean
}

export default ({ children, ...props }: Props) => {
  const [visible, setVisible] = useState(false)
  const { ADMIN, ACCOUNTANT } = useLogin()

  function handleClose() {
    setVisible(false)
    props.handleActionClick(props.invoiceId, false)
  }

  function handleBack() {
    setVisible(true)
  }

  function handleSetVisible() {
    props.handleActionClick(props.invoiceId, true)
    setVisible(true)
  }
  return (
    <Dropdown
      overlay={
        <ActionsOverlay
          {...props}
          handleClose={handleClose}
          handleBack={handleBack}
          unapproveInvoices={props.unapproveInvoice}
          selectedInvoicesStatuses={props.selectedInvoiceStatuses}
          showInvoiceDetail={props.showInvoiceDetail}
        />
      }
      trigger={['click']}
      visible={(!ADMIN && props.approvedInvoice) || ACCOUNTANT ? false : visible}
      onVisibleChange={handleSetVisible}
    >
      {children}
    </Dropdown>
  )
}
