import path from 'path-browserify'
import { InvoiceFilesType, InvoiceType } from '../queries/invoices'

export const isPdf = (file: string) => {
  return file.endsWith('.pdf')
}

export function isHeic(file: File) {
  if (file) {
    const extension = path.extname(file.name).toLowerCase()
    return extension === '.heic' || extension === '.heif'
  }
}

export const getFilePath = (invoice: InvoiceType, file: InvoiceFilesType, token: string) => {
  if (!file?.url) {
    return ''
  }

  return file.url.startsWith('blob')
    ? file.url
    : `${process.env.REACT_APP_API_URL}/invoices/${invoice?.id}/${file.order}/file?access_token=${token}`
}

export const getFileExtension = (file: InvoiceFilesType) => path.extname(file.url).toLowerCase()
