import React, { Dispatch, SetStateAction, useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { SelectInput } from '../layout/Form/Input'
import { OptionsType } from '../layout/Form/OptionsType'
import { InputValueStateType, UserGroupInvoiceType } from '../../constants/types'
import { HandleChangeEvent } from '../layout/Form/Input'
import { HeaderLine, LeftGroupSection, RightSection } from '../../components/screens/Invoice/Main'
import comparePrice from '../../utils/comparePrice'
import { issuerRightSection, issuerLeftSection, FormDataType } from '../../constants/formsData'
import IssuerField from './IssuerField'
import { TYPES } from '../../constants/formsData'
import handleMathSymbols from '../../utils/handleMathSymbols'
import type { InputClickHandle } from '../../constants/types'
import { calculatePriceWithoutVat, calculatePriceWithVat } from '../../utils/calculatePrice'

interface Props {
  user: UserGroupInvoiceType
  isAdmin: boolean | undefined
  detail?: boolean
  setClosedRemoveIssuerPopUp: Dispatch<SetStateAction<boolean>>
  setRemoveIssuerId: Dispatch<SetStateAction<number | null>>
  selectedUsers: UserGroupInvoiceType[]
  setSelectedUsers: Dispatch<SetStateAction<UserGroupInvoiceType[]>>
  getSelectOptions: (item: FormDataType | null, name?: string) => OptionsType[]
  disableInputValue: string
  isCategoryTaxable: (category: string) => boolean
  disabled: boolean
  index: number
}

const GroupInvoice = ({
  user,
  isAdmin,
  detail,
  setClosedRemoveIssuerPopUp,
  setRemoveIssuerId,
  selectedUsers,
  setSelectedUsers,
  getSelectOptions,
  disableInputValue,
  isCategoryTaxable,
  disabled,
  index,
}: Props) => {
  const [inputValue, setInputValue] = useState<InputValueStateType>({})
  const [realPrice, setRealPrice] = useState<string>('0')
  const [radioButtonsDisabled, setRadioButtonsDisabled] = useState<boolean>(false)

  const getRealPrice = (radioButtonValue: string, price: string, vatPrice: string): number => {
    const realPrice =
      radioButtonValue === 'Deductible'
        ? parseFloat(vatPrice && vatPrice.replace(',', '.').replace(/\s/g, ''))
        : parseFloat(price && price.replace(',', '.').replace(/\s/g, ''))
    return isNaN(realPrice) ? 0 : realPrice
  }

  const nonTaxableRealPrice = (realPrice: string): number => {
    let vatPrice = parseFloat(realPrice.replace(',', '.')) / 0.81
    return Math.round(vatPrice * 100) / 100
  }

  const inputValueHandler = useCallback((data: InputValueStateType) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      ...data,
    }))
  }, [])

  const updateSelectedUsers = useCallback(
    (current: UserGroupInvoiceType[], e?: HandleChangeEvent, item?: FormDataType) => {
      if (!item || !e) {
        return current
      }

      const newUsers = current.map((selectedUser) => {
        if (selectedUser.id === user.id) {
          const updatedValue = {
            value: e?.target.value,
            isInvalid:
              (item.required && e?.target.value === '') ||
              (item.regExp && !item.regExp.test(e?.target.value)) ||
              comparePrice(item, e, selectedUser),
          }

          inputValueHandler({ [item.name]: updatedValue })

          return {
            ...selectedUser,
            [item.name]: updatedValue,
          }
        }
        return selectedUser
      })

      return newUsers
    },
    [inputValueHandler]
  )

  const handleUserInputChange = useCallback(
    (item?: FormDataType, e?: HandleChangeEvent) => {
      setSelectedUsers((prevSelectedUsers) => {
        const updatedUsers = prevSelectedUsers.map((selectedUser) => {
          if (selectedUser.id !== user.id || !e || !item) return selectedUser

          let value: string
          if (item.name === 'price' || item.name === 'vatPrice') {
            value = handleMathSymbols(e?.target.value).toString()
          } else {
            value = e?.target.value
          }

          const issuer = {
            ...selectedUser,
            [item.name]: {
              value: value,
              isInvalid:
                (item.required && value === '') ||
                (item.regExp && !item.regExp.test(value)) ||
                comparePrice(item, e, selectedUser),
            },
          }

          const isTaxable = isCategoryTaxable(issuer.categorySelect.value)

          setRadioButtonsDisabled(!isTaxable)

          const realPrice = getRealPrice(
            !isTaxable ? 'Non-Deductible' : issuer.vatState.value,
            issuer.price.value,
            issuer.vatPrice.value
          ).toString()

          const updatedRealPrice = isTaxable ? realPrice : nonTaxableRealPrice(realPrice).toString()
          setRealPrice(updatedRealPrice)

          issuer.realPrice.value = updatedRealPrice

          return issuer
        })

        return updatedUsers
      })
    },
    [selectedUsers, updateSelectedUsers]
  )

  const handleRadioButtonChange = useCallback(
    (e: HandleChangeEvent) => {
      const vatState: FormDataType = {
        type: TYPES.RADIO_BUTTON,
        text: 'Non-Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'vatState',
        defaultChecked: true,
      }
      handleUserInputChange(vatState, e)
    },
    [handleUserInputChange]
  )

  useEffect(() => {
    const { issuerSelect, vatState, vatPrice, price, realPrice, categorySelect } = user
    inputValueHandler({
      issuerSelect: {
        value: issuerSelect.value ?? getSelectOptions(null, 'issuerSelect')[0].value,
        isInvalid: issuerSelect.isInvalid,
      },
      vatState: { value: vatState.value, isInvalid: user.vatState.isInvalid },
      vatPrice: { value: vatPrice.value, isInvalid: user.vatPrice.isInvalid },
      price: { value: price.value, isInvalid: user.price.isInvalid },
      categorySelect: {
        value: categorySelect.value ?? getSelectOptions(null, 'categorySelect')[0].value,
        isInvalid: user.categorySelect.isInvalid,
      },
      realPrice: { value: realPrice.value ?? 0, isInvalid: user.realPrice.isInvalid },
    })

    setRadioButtonsDisabled(!isCategoryTaxable(categorySelect.value))
  }, [selectedUsers.length, user, getSelectOptions, inputValueHandler])

  function handleValueChange(name: string, value: string) {
    if (!value || !name) {
      return
    }

    const initializer = name === 'price' ? 'vatPrice' : 'price'

    const inputData = {
      ...inputValue,
      ...{
        [name]: {
          value,
          isInvalid: false,
        },
        [initializer]: {
          value: inputValue?.[initializer]?.value,
          isInvalid: false,
        },
      },
    }

    const newValue: FormDataType = {
      type: TYPES.RADIO_BUTTON,
      text: 'Non-Deductible',
      required: true,
      width: 21.5,
      mobileWidth: 21.5,
      height: 2.875,
      name: name,
      defaultChecked: true,
    }

    handleUserInputChange(newValue, { target: { value: value } })
    setInputValue(inputData)
  }

  const inputClickHandles: InputClickHandle[] = [
    {
      handle: 'wovat',
      function: () =>
        calculatePriceWithoutVat({
          value: inputValue.price.value,
          callback: handleValueChange,
        }),
    },
    {
      handle: 'vat',
      function: () =>
        calculatePriceWithVat({ value: inputValue.vatPrice.value, callback: handleValueChange }),
    },
  ]

  return (
    <React.Fragment key={index}>
      <HeaderLine src="/img/separator.svg" />
      <GroupInvoiceContainer data-testid={`issuer-${index}`}>
        <LeftGroupSection>
          <IssuerField
            isAdmin={isAdmin}
            issuerLeftSection={issuerLeftSection}
            inputValue={inputValue}
            selectedUsers={selectedUsers}
            detail={detail}
            userId={user.id}
            handleUserInputChange={handleUserInputChange}
            setClosedRemoveIssuerPopUp={setClosedRemoveIssuerPopUp}
            setRemoveIssuerId={setRemoveIssuerId}
            getSelectOptions={getSelectOptions}
          />
        </LeftGroupSection>
        <RightSection disabled={detail} isGroupInvoice={true}>
          {issuerRightSection.map((item: FormDataType, key: number) => (
            <React.Fragment key={`${item.name}_${index}_right`}>
              <SelectInput
                key={`${item.name}_${index}_right`}
                item={item}
                isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
                value={inputValue[item.name] && inputValue[item.name].value}
                inputValue={inputValue}
                handleRadioButtonChange={handleRadioButtonChange}
                handleChange={handleUserInputChange}
                options={getSelectOptions(item)}
                mobileSmaller={true}
                isRadioButtonDisabled={detail || radioButtonsDisabled}
                isInputDisabled={false}
                disabledInputValue={realPrice}
                radioButtonValue={
                  inputValue?.vatState?.value ? inputValue?.vatState?.value : 'Deductible'
                }
                disabled={disabled || item.name === 'realPrice'}
                searchable={item.name === 'categorySelect' || item.name === 'issuerSelect'}
                index={index}
                inputClickHandles={inputClickHandles}
              />
              {/* <Margin isLast={key >= issuerRightSection.length - 1} /> */}
            </React.Fragment>
          ))}
        </RightSection>
      </GroupInvoiceContainer>
    </React.Fragment>
  )
}

interface MarginProps {
  isLast: boolean
}

const Margin = styled.div<MarginProps>`
  margin-top: ${({ isLast }) => (isLast ? '0' : '1rem')};
  pointer-events: none;
`

const GroupInvoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`

export default GroupInvoice
