import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  disabledText?: boolean
  onClick?: () => void
}

export default ({ text, disabledText, onClick }: Props) => {
  return (
    <InputText
      onClick={() => !disabledText && onClick && onClick()}
      disabledText={disabledText}
      hasClickFunction={typeof onClick === 'function'}
    >
      {text}
    </InputText>
  )
}

interface InputTextProps {
  disabledText?: boolean
  hasClickFunction?: boolean
}

const InputText = styled.span<InputTextProps>`
  display: flex;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.81em;
  line-height: 1em;
  justify-content: flex-start;
  color: ${(props) => (props.disabledText ? '#6D7481' : 'white')};
  cursor: ${(props) => (props.hasClickFunction && !props.disabledText ? 'pointer' : 'default')};
  padding-bottom: 0.8em;

  @media (max-width: 560px) {
    font-size: 0.688em;
  }
`
