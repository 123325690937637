import React, { useState } from 'react'
import styled from 'styled-components'
import links, { LinkType } from '../../../constants/links'
import { Link } from 'react-router-dom'
import StyledText from './StyledText'
import 'rc-dropdown/assets/index.css'
import '../Dropdown.css'
import CategoryOverlay from './ItemsOverlay/CategoryOverlay'
import StatusOverlay from './ItemsOverlay/StatusOverlay'
import SubDropdownMenu from './SubDropdownMenu'
import handleRedirectToEdit from '../handleRedirectToEdit'
import handleRedirectToDetail from '../handleRedirectToDetail'
import { useNavigate, useLocation } from 'react-router'
import DeletePopUp from '../PopUps/DeletePopUp'
import useLogin from '../../../hooks/useLogin'
import { LocationType } from '../../../constants/types'
import { useParams } from 'react-router'
import { ParamsType } from '../ParamsType'
import handleRedirectToDuplication from '../handleRedirectToDuplication'

interface Props {
  handleClose: () => void
  handleBack: () => void
  unapproveInvoices: () => void
  deleteInvoices: () => void
  approveInvoices: () => void
  approvedInvoice: boolean
  selectedInvoices: number
  selectedInvoiceCategories: string[]
  selectedInvoicesStatuses: string[]
  selectedInvoicesIds: string[]
  variables: any
  showInvoiceDetail: () => void
  closedMonth: boolean
  isConcept?: boolean
}

export default (props: Props) => {
  const [visibleCategory, setVisibleCategory] = useState(false)
  const [visibleStatus, setVisibleStatus] = useState(false)
  const [deletePopUpClosed, setDeletePopUpClosed] = useState(true)
  const { ADMIN } = useLogin()

  const navigate = useNavigate()
  const params = useParams<ParamsType>() as ParamsType
  const location = useLocation()

  function handleCloseCategory() {
    setVisibleCategory(false)
  }

  function handleCloseStatus() {
    setVisibleStatus(false)
  }

  function handleClose() {
    handleCloseStatus()
    handleCloseCategory()
    props.handleClose()
  }

  function chooseOnClick(link: LinkType) {
    if (props.selectedInvoices === 0) {
      return () => null
    }

    let onClickProps = props.deleteInvoices

    switch (link.text) {
      case 'Unapprove':
        return (onClickProps = props.unapproveInvoices)
      case 'Approve':
        return (onClickProps = props.approveInvoices)
      case 'Delete':
        return (onClickProps = () => setDeletePopUpClosed(false))
      case 'Preview':
        return (onClickProps = () => {
          props.showInvoiceDetail()
          props.handleClose()
        })
      case 'Detail':
        return (onClickProps = () => {
          handleRedirectToDetail(props.selectedInvoicesIds[0], navigate, location.pathname, params)
        })
      case 'Edit':
        return (onClickProps = () =>
          handleRedirectToEdit(props.selectedInvoicesIds[0], navigate, location.pathname, params))
      case 'Duplicate':
        return (onClickProps = () =>
          handleRedirectToDuplication(
            props.selectedInvoicesIds[0],
            navigate,
            location.pathname,
            params
          ))
    }

    return onClickProps
  }

  function renderLinks() {
    return links.map((link: LinkType, index: number) => {
      if (!ADMIN && link.onlyAdmin) {
        return null
      }

      if (props.closedMonth && !link.inClosedMonth && !link.onlyAdminInClosedMonth) {
        return null
      }

      if (link.text === 'Category') {
        return (
          <SubDropdownMenu
            onVisibleChange={setVisibleCategory}
            visible={visibleCategory}
            text={link.text}
            overlay={
              <CategoryOverlay
                handleBack={handleCloseCategory}
                handleClose={handleClose}
                variables={props.variables}
                selectedInvoiceCategories={props.selectedInvoiceCategories}
                selectedInvoiceIds={props.selectedInvoicesIds}
              />
            }
          />
        )
      }

      if (link.text === 'Status') {
        return (
          <SubDropdownMenu
            onVisibleChange={setVisibleStatus}
            visible={visibleStatus}
            overlay={
              <StatusOverlay
                handleBack={handleCloseStatus}
                handleClose={handleClose}
                variables={props.variables}
                selectedInvoiceStatuses={props.selectedInvoicesStatuses}
                selectedInvoiceIds={props.selectedInvoicesIds}
              />
            }
            text={link.text}
          />
        )
      }

      if (
        ((props?.isConcept || props.approvedInvoice) && link.text === 'Approve') ||
        (!props.approvedInvoice && link.text === 'Unapprove')
      ) {
        return null
      }

      if (
        (link.text === 'Edit' || link.text === 'Preview') &&
        props.selectedInvoicesIds.length > 1
      ) {
        return null
      }

      if (link.clickable) {
        return (
          <StyledClickable
            colorRed={link.colorRed && link.colorRed}
            key={index}
            onClick={chooseOnClick(link)}
          >
            {link.text}
          </StyledClickable>
        )
      }

      return (
        <StyledLink to={link.url} colorRed={link.colorRed && link.colorRed} key={index}>
          {link.text}
        </StyledLink>
      )
    })
  }

  return (
    <>
      <DeletePopUp
        closed={deletePopUpClosed}
        handleClosed={() => setDeletePopUpClosed(true)}
        handleDelete={props.deleteInvoices}
        deleteItemText={props.selectedInvoices > 1 ? 'these' : 'this'}
        kindOfDelete={props.selectedInvoices > 1 ? 'invoices' : 'invoice'}
      />
      {renderLinks()}
    </>
  )
}

interface StyledLinkProps {
  colorRed: any
}

const StyledLink = styled(Link)<StyledLinkProps>`
  ${StyledText}
  text-decoration: none;
  margin-top: 3em;
  margin-left: 1em;
  color: ${(props) => (props.colorRed ? '#F45151' : 'white')};
`

const StyledClickable = styled.span<StyledLinkProps>`
  ${StyledText}
  margin-top: 3em;
  margin-left: 1em;
  cursor: pointer;
  color: ${(props) => (props.colorRed ? '#F45151' : 'white')};
`
